//styles
import styled, { css } from 'styled-components';
import vars from './varss';

const Container = styled.div`
  width: 100%;
  max-width: ${props => props.maxWidth ? props.maxWidth : vars.maxWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: ${vars.media.smMax}) {
    padding-left: 16px;
    padding-right: 16px;
  }
  /* no margin */
  ${props => props.fullscreen && css`
    padding-left: 0;
    padding-right: 0;
    @media (max-width: ${vars.media.smMax}) {
      padding-left: 0;
      padding-right: 0;
    }
  `}
`;

export default Container;