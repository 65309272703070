const vars = {
  colors: {
    text: '#000000',
    grey: '#555555',
    red1: '#ff545e',
    red2: '#fd0402',
    orange: '#ff7148',
    yellow: '#ffbc00',
    green1: '#00a991',
    green2: '#037172',
    green3: '#34d5ac',
    blue: '#188bf6',
    teal: '#20b0d2',
    pink: '#ff3e7f',
    purple: '#5a4eff',
  },
  fonts: {
    fontSize: '1rem',
    fontFamily: 'Degular, Roboto, system, -apple-system, BlinkMacSystemFont, ".SFNSDisplay-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: 1.25,
  },
  media: {
    smMin: '576px',
    mdMin: '768px',
    lgMin: '992px',
    xlMin: '1200px',
    xxlMin: '1400px',
    xsMax: '575px',
    smMax: '767px',
    mdMax: '991px',
    lgMax: '1199px',
    xlMax: '1399px',
  },
  transitions: {
    hover1: 'all 0.2s ease-in-out 0s', 
    hover2: 'color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s, border 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s', 
    hover3: 'transform .5s cubic-bezier(.38, .32, .36, .98) 0s',
    hover4: 'all .3s cubic-bezier(.38, .32, .36, .98) 0s',
  },
  maxWidth: '100%',
  navHeight: 80,
}

export default vars;