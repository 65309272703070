import { css, createGlobalStyle } from 'styled-components'
import { rem } from 'polished';
import vars from './varss';

//Web Fonts
import RobotoSlabThin1 from '../assets/fonts/RobotoSlab-Thin.ttf';
import RobotoSlabThin2 from '../assets/fonts/RobotoSlab-Thin.woff';
import RobotoSlabThin3 from '../assets/fonts/RobotoSlab-Thin.woff2';
import DegularRegular1 from '../assets/fonts/Degular-Regular.ttf';
import DegularRegular2 from '../assets/fonts/Degular-Regular.woff';
import DegularRegular3 from '../assets/fonts/Degular-Regular.woff2';
import DegularLight1 from '../assets/fonts/Degular-Light.ttf';
import DegularLight2 from '../assets/fonts/Degular-Light.woff';
import DegularLight3 from '../assets/fonts/Degular-Light.woff2';

const typography = css`
  /* My Typography Styles */
  html : {
    background-color: #FFFFFF;
  }
  body {
    background-color: #FFFFFF;
    font-size: ${vars.fonts.fontSize};
    font-family: ${vars.fonts.fontFamily};
    font-weight: ${vars.fonts.fontWeight};
    line-height:  ${vars.fonts.lineHeight};
    color: ${vars.colors.text};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: background-color .3s ease-in-out;
  }
  a,
  button {
    transition: ${vars.transitions.hover2};
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      outline: none;
    }
    &:active {
      &:focus {
        outline: none;
      }
    }
    &[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: none !important;
      font-size: inherit !important;
      font-family: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
    }
  }
  a {
    color: #000;
    &:hover,
    &:active,
    &:focus {
      color: #000;
    }
  }
  p {
    margin-bottom: 0;
    + p {
      margin-top: ${rem('20px')};
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${vars.colors.textDark};
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: ${rem('20px')};
  }
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1rem;
  }
  .link-line {
    display: inline-block;
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      display: block;
      background-color: #000;
      transition: ${vars.transitions.hover3};
      transform: scaleX(0);
      transform-origin: right center;
      margin: auto;
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &:hover {
      &::before {
        transform:scale(1);
        transform-origin: left center;
      }
    }
  }
  /* Degular */
  @font-face {
    font-family: 'Degular';
    src: url(${DegularRegular1}) format('woff2'),
         url(${DegularRegular2}) format('woff'),
         url(${DegularRegular3}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Degular';
    src: url(${DegularLight1}) format('woff2'),
         url(${DegularLight2}) format('woff'),
         url(${DegularLight3}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
  }
  /* Roboto Slab */
  @font-face {
    font-family: 'RobotoSlab';
    src: url(${RobotoSlabThin1}) format('woff2'),
         url(${RobotoSlabThin2}) format('woff'),
         url(${RobotoSlabThin3}) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: fallback;
  }
  /* Cursor */
  /* html *,
  body * {
    cursor: none;
  } */
`;

export const Typography = createGlobalStyle`${typography}`;

export default typography;