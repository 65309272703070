import React, { useEffect, useContext } from 'react';
//router
import { useLocation } from '@reach/router';
//context
import { GlobalContext } from '../context/context';
//style
import styled, { css, createGlobalStyle } from 'styled-components';
import reset from './reset';
import typography from './typography';
import smooth from './smooth';
//components
import Loading from './loading';
import PageTransition from './pageTransition';
//framer-motion
import { AnimatePresence } from 'framer-motion';
//grid
import { GridThemeProvider } from 'styled-bootstrap-grid';

//Reset & Default Styles
const GlobalStyle = createGlobalStyle`
  ${reset};
  ${typography};
  ${smooth};
`;

//styled
const LayoutWrap = styled.div`
  //position: relative;
  overflow: hidden;
  height: 100vh;
  padding-top: 40px;
  /* loaded */
  ${props => props.loaded && css`
    height: auto;
    overflow: visible;
  `}
`;

const Main = styled.main`
  //position: relative;
  //min-height: 100vh;
  .scrollbar {
    height: 100vh;
  }
`;

const gridTheme = {
  row: {
    padding: 10, // default 15
  },
  col: {
    padding: 10, // default 15
  },
};

const Layout = (props) => {

  //use context
  const [context, setContext] = useContext(GlobalContext);

  //location
  const location = useLocation();

  //select body element
  //const bodyElem = typeof document !== `undefined` ? document.querySelector('body') : null;

  //loaded
  const isLoaded = () => {
    setTimeout(() => {
      setContext({
        ...context,
        loaded: true,
      });
      
    }, 3500);
  };

  useEffect(() => {
    isLoaded();
    // eslint-disable-next-line
  }, []);

  return (
    <LayoutWrap loaded={context.loaded} data-scroll-section>
      <GridThemeProvider gridTheme={gridTheme}>
        <GlobalStyle />
        {!context.loaded && <Loading loaded={context.loaded} />}
        <Main>
          <AnimatePresence exitBeforeEnter>
            <PageTransition location={location.pathname}>
              {props.children}
            </PageTransition>
          </AnimatePresence>
        </Main>
      </GridThemeProvider>
    </LayoutWrap>
  );
};

export default Layout;