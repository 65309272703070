import React, { useState, useEffect } from 'react';
//style
import styled, { css } from 'styled-components';
//framer-motion
import { motion } from 'framer-motion';

//styled
const LoadingWrap = styled(motion.div)`
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* loaded */
  ${props => props.loaded && css`
    pointer-events: none;
    z-index: -9;
  `}
  .bg {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
  }
  .text-wrap {
    position: relative;
    z-index: 2;
  }
  h1 {
    font-size: 29.5vw;
    line-height: 1;
    letter-spacing: -2vw;
    color: #fff;
    text-align: center;
    font-weight: 100;
    overflow: hidden;
    white-space: nowrap;
    > span {
      display: inline-block;
      &.space {
        margin-right: 3vw;
      }
    }
  }
`;

const AnimateBG = {
  animate: {
    y: '-100%',
    transition: {
      ease: [0.48, 0.04, 0.52, 0.96],
      duration: 0.8,
      delay: 2.5,
    }
  }
};

const AnimateTextWrap = {
  animate: {
    y: '-55vh',
    opacity: 0,
    transition: {
      ease: [0.48, 0.04, 0.52, 0.96],
      duration: 0.8,
      delay: 2.6,
    }
  }
};

const AnimateText = {
  animate: {
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.05,
    }
  }
};

const FadeUp = {
  initial: {
    y: '100%',
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.76, 0, 0.24, 1],
      duration: 1.5,
    }
  }
};


const Loading = ({ loaded }) => {

  //state
  const [state, setState] = useState(false);
  //set page mount
  useEffect(() => {
    setState(true);
    return () => {
      setState(false);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <LoadingWrap
      loaded={loaded}
      initial={false}
      animate={state ? 'animate' : 'initial'}
      >
      <motion.span className="bg" variants={AnimateBG} />
      <motion.div className="text-wrap" variants={AnimateTextWrap}>
        <motion.h1 variants={AnimateText}>
          <motion.span variants={FadeUp}>J</motion.span>
          <motion.span variants={FadeUp}>a</motion.span>
          <motion.span variants={FadeUp}>k</motion.span>
          <motion.span variants={FadeUp}>u</motion.span>
          <motion.span variants={FadeUp} className="space">b</motion.span>
          <motion.span variants={FadeUp}>R</motion.span>
          <motion.span variants={FadeUp}>e</motion.span>
          <motion.span variants={FadeUp}>i</motion.span>
          <motion.span variants={FadeUp}>s</motion.span>
        </motion.h1>
      </motion.div>
    </LoadingWrap>
  );
};

export default Loading;